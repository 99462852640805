import './App.css';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {

  return (
    <>
    <div className="div">
      <div className="div-2">
        <div className="div-3">
          <div className="column">
            <div className="div-4">
              <div className="div-5">Josh Baker</div>
              <div className="div-6">Software Engineer</div>
              <div className="div-7">
                An engineer with a passion for all things tech related!
              </div>
              <div className="div-8">
              <a href="https://github.com/JBakes98" className='img'>
                        <FontAwesomeIcon icon={faGithub} size="2xl" style={{ color: "#ffffff", }} />
                    </a>
                <a href="https://www.linkedin.com/in/joshua-baker-588b28155/" className='img'>
                        <FontAwesomeIcon icon={faLinkedin} size="2xl" style={{ color: "#ffffff", }} />
                    </a>
              </div>
            </div>
          </div>
          <div className="column-2">
            <img
              loading="lazy"
              src={require("./Images/hero.png")}
              className="img-2"
            />
          </div>
        </div>
      </div>
    </div>
    
    <style jsx>{`
      .div {
        justify-content: center;
        align-items: center;
        background-color: #010026;
        display: flex;
        padding: 0 60px 80px;
        height: 100vh;
      }
      @media (max-width: 991px) {
        .div {
          padding: 0 20px;
        }
      }
      .div-2 {
        width: 100%;
        max-width: 1065px;
      }
      @media (max-width: 991px) {
        .div-2 {
          max-width: 100%;
        }
      }
      .div-3 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .div-3 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .column {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 33%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .column {
          width: 100%;
        }
      }
      .div-4 {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        margin: auto 0;
      }
      @media (max-width: 991px) {
        .div-4 {
          margin-top: 40px;
        }
      }
      .div-5 {
        color: #fff;
        font: 700 65px Outfit, sans-serif;
      }
      @media (max-width: 991px) {
        .div-5 {
          font-size: 40px;
        }
      }
      .div-6 {
        color: #fff;
        margin-top: 27px;
        font: 100 40px Outfit, sans-serif;
      }
      .div-7 {
        color: #fff;
        margin-top: 59px;
        font: 300 26px Outfit, sans-serif;
      }
      @media (max-width: 991px) {
        .div-7 {
          margin-top: 40px;
        }
      }
      .div-8 {
        display: flex;
        margin-top: 87px;
        padding-right: 80px;
        gap: 20px;
      }
      @media (max-width: 991px) {
        .div-8 {
          padding-right: 20px;
          margin-top: 40px;
        }
      }
      .img {
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 50px;
        fill: rgba(255, 255, 255, 0.5);
      }
      .column-2 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 67%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .column-2 {
          width: 100%;
        }
      }
      .img-2 {
        aspect-ratio: 1.19;
        object-fit: auto;
        object-position: center;
        width: 100%;
        flex-grow: 1;
      }
      @media (max-width: 991px) {
        .img-2 {
          max-width: 100%;
          margin-top: 15px;
        }
      }
    `}</style>
  </>
);
}



export default App;
